@font-face {
  font-family: Gilroy Light;
  weight: 300;
  font-style: italic;
  src: url("font.8e9d009a.otf") format("opentype"), url("font.7219b124.woff2") format("woff2"), url("font.292eac0a.woff") format("woff");
}

@font-face {
  font-family: Gilroy RegularItalic;
  weight: 400;
  font-style: italic;
  src: url("font.c30f579d.otf") format("opentype"), url("font.714443a0.woff2") format("woff2"), url("font.610fcf78.woff") format("woff");
}

@font-face {
  font-family: Gilroy SemiBold;
  weight: 600;
  src: url("font.a4cc74ba.otf") format("opentype"), url("font.cb71a28c.woff2") format("woff2"), url("font.b8883cbf.woff") format("woff");
}

@font-face {
  font-family: Gilroy Heavy;
  weight: 900;
  src: url("font.3c800314.otf") format("opentype"), url("font.45528eb4.woff2") format("woff2"), url("font.52985083.woff") format("woff");
}

@font-face {
  font-family: Argesta;
  weight: 300;
  src: url("argestadisplay-regular-webfont.c51d1d95.woff2") format("woff2"), url("argestadisplay-regular-webfont.70e577ef.woff") format("woff");
}
/*# sourceMappingURL=index.102a8bed.css.map */
