@font-face {
    font-family: "Gilroy Light";
    weight: 300;
    font-style: italic;
    src: url("../fonts/gilroy/GilroyLight/font.otf") format("opentype"), url("../fonts/gilroy/GilroyLight/font.woff2") format("woff2"), url("../fonts/gilroy/GilroyLight/font.woff")
        format("woff");
}

@font-face {
    font-family: "Gilroy RegularItalic";
    weight: 400;
    font-style: italic;
    src: url("../fonts/gilroy/GilroyRegularItalic/font.otf") format("opentype"), url("../fonts/gilroy/GilroyRegularItalic/font.woff2") format("woff2"), url("../fonts/gilroy/GilroyRegularItalic/font.woff")
        format("woff");
}

@font-face {
    font-family: "Gilroy SemiBold";
    weight: 600;
    src: url("../fonts/gilroy/GilroySemiBold/font.otf") format("opentype"), url("../fonts/gilroy/GilroySemiBold/font.woff2") format("woff2"), url("../fonts/gilroy/GilroySemiBold/font.woff")
        format("woff");
}

@font-face {
    font-family: "Gilroy Heavy";
    weight: 900;
    src: url("../fonts/gilroy/GilroyHeavy/font.otf") format("opentype"), url("../fonts/gilroy/GilroyHeavy/font.woff2") format("woff2"), url("../fonts/gilroy/GilroyHeavy/font.woff")
        format("woff");
}

@font-face {
font-family: "Argesta";
weight: 300;
src: url("../fonts/Argesta-Display-Regular-web/argestadisplay-regular-webfont.woff2") format("woff2"), url("../fonts/Argesta-Display-Regular-web/argestadisplay-regular-webfont.woff")
    format("woff");
}
